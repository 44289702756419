* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(250,250,250);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1 {
  font-size: 40px;
  font-family: "Ubuntu", sans-serif;
  color: #1D2769;
}

h2 {
  font-size: 24px;
  font-family: "Ubuntu", sans-serif;
  color: #1D2769;
}

p {
 font-family: "Ubuntu", sans-serif;
 font-size: 16px;
 color: #1D2769;
}

p.small {
  font-size: 14px;
}

p.bold {
  font-weight: bold;
}

input, option, select {
  color: #1E1E1E;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
}